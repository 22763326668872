import NavBar from "./NavBar";
import Bureau from "../Assets/pexels-karolina-grabowska-large.jpg";
import Bureau2 from "../Assets/pexels-karolina-grabowska-large.jpg";
import logoSophie from "../Assets/My project-1 (1).png";
import Divider from "@mui/material/Divider";
import ScrollDown from "../Assets/icons8-double-bas-50.png";
import Sophie from "../Assets/IMG20230614165205.jpg";
import Therapie from "../Assets/therapie.png";
import Sophro from "../Assets/Sophro.png";
import Hypnotherapie from "../Assets/hypnotherapie.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Lotus from "../Assets/icons8-lotus-50.png";
import Footer from "./Multi_Component/Footer";

const Home = () => {
  const navigate = useNavigate();
  const [displaySeance, setDisplaySeance] = useState(false);
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <>
      <NavBar />

      <div className="containerImgSophieDesk">
        <img className="sophieDesk" src={Bureau} alt="bureauSophie" />
        <div className="textSophieDesk">
          <div className="divSophieDesk">
            <span>Thérapeute,</span> <span>Sophrologue,</span>{" "}
            <span>Hypnothérapeute</span> à Paray-Vieille-Poste
          </div>
          <div className="divider">
            <Divider
              variant="middle"
              width="100px"
              style={{
                borderColor: "#CB997E",
                borderRadius: "10px",
                marginBottom: "30px",
              }}
            />
          </div>
          <div className="divSophieDeskSecond">
            <span>{`"Le bonheur c'est du bien être dont on prend conscience" (Christophe André)`}</span>
          </div>
        </div>
      </div>
      <div className="profilecontentframeHome">
        <div
          style={{
            textAlign: "-webkit-center",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <h4><span style={{color:"#CB997E"}}>Bonjour et Bienvenue…</span></h4>
          <div className="divider">
            <Divider
              variant="middle"
              width="100px"
              style={{
                borderColor: "#CB997E",
                borderRadius: "10px",
                // marginBottom: "30px",
              }}
            />
          </div>
        </div>
        <div style={{ padding: "0px 20px 0px 20px" }}>
          <img
            src={Sophie}
            alt="photo1"
            style={{
              width: "150px",
              height: "200px",
              float: "left",
              margin: "20px",
              borderRadius: "60px",
            }}
          />
          <p>
            Je suis Sophie Mele, Thérapeute certifié en Hypnose Sajece,
            Sophrologie Caycedienne et psycho-praticienne spécialisé enfants et
            adolescents. <br />
            J’ai à coeur de vous accompagner dans vos libérations d’émotions et
            de vous aider à retrouver espoir, confiance et bienveillance envers
            vous-même. <br />
            Mon mantra depuis toujours est la <span style={{color:"#CB997E"}}>PURA VIDA</span>, qui signifie vie pure.
            <br />
            Chacun possède sa propre définition d’une vie pure, d’une vie
            heureuse, d’une vie épanouissante.
            <br />
            Mon travail est de vous aider à trouver votre propre chemin du
            bonheur, votre chemin de la guérison et du bien être. Votre <span style={{color:"#CB997E"}}>PURA VIDA</span> à vous !<br />
            J’expérimente moi même ce travail depuis plusieurs années et je suis
            convaincue des bienfaits des thérapies de libération par la parole,
            par la sophrologie et par l’hypnose. <br />
            Après plusieurs années dans le secteur bancaire, j’ai souhaité me
            former aux différentes techniques thérapeutique afin de pouvoir, à
            mon tour, aider et accompagner.
            <br />
          </p>
        </div>
        <div>
        <div className="divider" style={{textAlign : "-webkit-center"}}>
            <Divider
              variant="middle"
              width="100px"
              style={{
                borderColor: "#CB997E",
                borderRadius: "10px",
                // marginBottom: "30px",
            
              }}
            />
          </div>
      
          <h4 style={{textAlign:"center",color:"#CB997E"}}>Les séances : </h4>
          <div className="divider" style={{textAlign : "-webkit-center",marginBottom:"30px"}}>
            <Divider
              variant="middle"
              width="100px"
              style={{
                borderColor: "#CB997E",
                borderRadius: "10px",
                // marginBottom: "30px"
              }}
            />
          </div>
      
        <div style={{ padding: "0px 20px 0px 20px", margin: "auto" }}>
          <div style={{display:"flex",flexWrap:"wrap",justifyContent:"center",gap:"15px"}}>
            <div
              className="containerTypeSeance"
              //   style={{marginLeft : "10%"}}
            >
              <button
                className="btnTypeSeanceHome"
                onClick={() => {navigate("/therapie");window.scrollTo(0, 0); }}
              >
                <img
                  src={Therapie}
                  className="imgBtnTypeSeance"
                  alt="therapieEnfant"
                />
              </button>
            </div>
            <div
              className="containerTypeSeance"
              // style={{marginLeft : "50%"}}
            >
              <button
                className="btnTypeSeanceHome"
                onClick={() => {navigate("/sophrologie");window.scrollTo(0, 0)}}
              >
                <img
                  src={Sophro}
                  className="imgBtnTypeSeance"
                  alt="therapieEnfant"
                />
              </button>
            </div>
            <div
              className="containerTypeSeance"
              // style={{marginLeft : "10%"}}
            >
              <button
                className="btnTypeSeanceHome"
                onClick={() => {navigate("/hypnotherapie");window.scrollTo(0, 0)}}
              >
                <img
                  src={Hypnotherapie}
                  className="imgBtnTypeSeance"
                  alt="therapieEnfant"
                />
              </button>
            </div>
          </div>
         
        </div>
        <div style={{ display: "flex", justifyContent: "center",marginTop:"50px" }}>
            <button className="btnRDV" onClick={() => navigate("/rdvlist")}>
              PRENDRE RDV
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
