import { useParams } from "react-router-dom"
import NavBar from "./NavBar"

const Rdv = () => {
    const {id} = useParams()
    return(<>
    <NavBar/>
    {id}
    </>)
}

export default Rdv