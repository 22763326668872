import React from "react";
import "../../Styles/Footer.css";
import { FaInstagram, FaEnvelope, FaPhone, FaMapMarker } from "react-icons/fa";
import Divider from "@mui/material/Divider";

function Footer() {
  return (
    <footer>
      <Divider
        variant="middle"
        width="80%"
        style={{
          borderColor: "#CB997E",
          borderRadius: "10px",
          marginBottom: "30px",
        }}
      />
      <h4 className="tilte_contacter">Me contacter :</h4>
      <div className="footer-icons">
        <a
          href="https://www.instagram.com/sophie_m_therapie"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram size={24} className="icone" />
          sophie_m_therapie
        </a>
        <a href="mailto:sophie.hypnosophro@gmail.com">
          <FaEnvelope size={24} className="icone" />
          sophie.hypnosophro@gmail.com
        </a>
        <a href="tel:+33663469178">
          <FaPhone size={24} className="icone" />
          06.63.46.91.78
        </a>

        <a
          href="https://www.google.fr/maps/place/110+Av.+d'Alsace-Lorraine,+91550+Paray-Vieille-Poste/@48.7149275,2.358233,17z/data=!3m1!4b1!4m6!3m5!1s0x47e675e754d1b611:0x4d182281da77afc5!8m2!3d48.7149275!4d2.3608079!16s%2Fg%2F11csjfzchk?entry=ttu"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaMapMarker size={24} className="icone" />
          110 Avenue d'Alsace Lorraine <br />
          91550 Paray Vieille Poste
        </a>
      </div>
      <div
        style={{
          fontSize: "9px",
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: "50px",
          color: "#c5a28a",
          fontStyle: "italic",
        }}
      >
        <a href="https://www.malt.fr/profile/elyesmele" target="_blank" rel="noopener noreferrer">Powered by @Mele_elyes</a> 
      </div>
    </footer>
  );
}

export default Footer;
