import NavBar from "./NavBar";
import Footer from "./Multi_Component/Footer"
import Agenda from "../Assets/pexels-ann-poan-5797898.jpg";
import Divider from "@mui/material/Divider";
import "../Styles/Therapie.css";
import flower from "../Assets/pexels-rodolfo-clix-922609.jpg";
import Photo1 from "../Assets/pexels-pok-rie-165213.jpg";
import Photo2 from "../Assets/pexels-arturo-david-17139355.png";

const Therapie = () => {
  return (
    <>
      <NavBar />

      <div className="containerTherapieTitle">
        <img className="therapieBackground" src={flower} alt="bureauSophie" />
        <div className="textSophiePresta">
          <div className="divSophiePresta">Therapie</div>
          <div className="divider">
            <Divider
              variant="middle"
              width="100px"
              style={{
                borderColor: "#CB997E",
                borderRadius: "10px",
                marginBottom: "30px",
              }}
            />
          </div>
        </div>
      </div>
      <div className="profilecontentframePresta">
        <div
          style={{
            textAlign: "-webkit-center",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <h4>Notre objectif commun : </h4>
          <div className="divider">
            <Divider
              variant="middle"
              width="100px"
              style={{
                borderColor: "#CB997E",
                borderRadius: "10px",
                // marginBottom: "30px",
              }}
            />
          </div>
        </div>
        <div style={{ padding: "0px 20px 0px 20px" }}>
          <img
            src={Photo1}
            alt="photo1"
            style={{
              width: "150px",
              height: "200px",
              float: "left",
              margin: "20px",
              borderRadius: "20px",
            }}
          />
          <p>
            Lors de notre premier rendez-vous, nous allons définir ensemble nos
            objectifs. <br />
            Ils seront determinés en fonction de vous, de vos besoins, de vos
            attentes et de votre rythme. <br />
            Les objectifs sont amenés à évoluer, changer, muter, par rapport au
            travail que nous ferons dans nos séances. <br />
            La confiance, l’investissement personnel, le lâcher prise
            progressif, seront les clés de votre accomplissement.
          </p>
        </div>
        <div
          style={{
            textAlign: "-webkit-center",
          }}
        >
          <div className="divider">
            <Divider
              variant="middle"
              width="100px"
              style={{
                borderColor: "#CB997E",
                borderRadius: "10px",
                // marginBottom: "30px",
              }}
            />
          </div>
        </div>
        <div
          style={{
            textAlign: "-webkit-center",
            paddingLeft: "15px",
            paddingRight: "15px",
            // marginBottom : "30px"
          }}
        >
          <h4>Mon approche : </h4>
          <div className="divider">
            <Divider
              variant="middle"
              width="100px"
              style={{
                borderColor: "#CB997E",
                borderRadius: "10px",
                marginBottom: "30px",
              }}
            />
          </div>
        </div>
        <div style={{ padding: "0px 20px 0px 20px", margin: "auto" }}>
          <img
            src={Photo2}
            alt="photo2"
            style={{
              width: "150px",
              height: "200px",
              float: "right",
              margin: "20px",
              borderRadius: "20px",
            }}
          />
          <p>
            Mon rôle est avant tout de vous écouter avec bienveillance et sans
            jugements. <br />
            En vous accompagnant dans votre démarche, je suis votre allié dans
            la libération par la parole de toutes vos blessures, vos
            souffrances, vos traumatismes, vos problématiques, vos blocages
            personnels. <br />
            Le respect mutuel est primordial et vous pourrez être vous même
            durant nos séances. <br />
            Je vous propose un accompagnement thérapeutique complet, vous
            permettant également d’avoir une séance de sophrologie ou une séance
            d’hypnose de 15 min à la fin de chacune de nos séances, si vous le
            souhaitez. <br />
            Au sein du cabinet règne un climat de confiance, de liberté et de
            sécurité. <br />
            Le chemin que nous allons parcourir ensemble sera fort en émotions
            et libérateur pour vous. <br />
            Ensemble, prenons la direction de votre nouvelle vie !
          </p>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default Therapie;
