import {Routes,Route} from "react-router-dom"
import './App.css';
import Home from "./Component/Home";
import NavBar from "./Component/NavBar";
import Contact from "./Component/Contact"
import Presta from "./Component/Presta";
import Therapie from "./Component/Therapie";
import Rdvlist from "./Component/Rdvlist";
import Rdv from "./Component/Rdv";
import Test from "./Component/test";
import Sophrologie from "./Component/Sophrologie";
import Hypnotherapie from "./Component/Hypnotherapie";

const App = () => {
  return (
    <div className="App">
     <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/contact_et_acces" element={<Contact/>}/>  
      <Route path="/presta_et_tarifs" element={<Presta/>}/> 
      <Route path="/sophrologie" element={<Sophrologie/>}/> 
      <Route path="/hypnotherapie" element={<Hypnotherapie/>}/>
      <Route path="/therapie" element={<Therapie/>}/> 
      <Route path="/rdvlist" element={<Rdvlist />} />
      <Route path="/rdv/:id" element={<Rdv />} />
     </Routes>
    </div>
  );
}

export default App;
