import React, { useState } from "react";
import NavBar from "./NavBar";
import "../Styles/Rdvlist.css";
import icone from "../Assets/icone.png";
import chaise from "../Assets/chaise.png";
import { useNavigate } from "react-router-dom";
import Agenda from "../Assets/pexels-ann-poan-5797898.jpg";
import Divider from "@mui/material/Divider";
import Calendly from "./Multi_Component/Calendly";
import Footer from "./Multi_Component/Footer";

const Rdvlist = () => {
  const navigate = useNavigate();
  const [profileHeight, setProfileHeight] = useState("50vh");

  const handleClick = () => {
    setProfileHeight("90vh");
  };

  return (
    <>
      <NavBar />
      <div className="containerImgSophieAgendaRdv">
        <img className="sophieAgendaRdv" src={Agenda} alt="agendaPicture" />
        <div className="textSophieAgenda">
          <div className="divSophieAgendaRdv">Prise de rendez-vous</div>
          <div className="divider">
            <Divider
              variant="middle"
              width="100px"
              style={{
                borderColor: "#CB997E",
                borderRadius: "10px",
                marginBottom: "30px",
              }}
            />
          </div>
        </div>
      </div>
      <div
        className="profilecontentframeRdv"
        style={{ height: profileHeight }}
        onClick={handleClick}
      >
        <Calendly />
      </div>
      <Footer />
    </>
  );
};

export default Rdvlist;
