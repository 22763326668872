import { Link, useNavigate } from "react-router-dom";
import "../Styles/NavBar.css";
import logoSophie from "../Assets/My project-1 (1).png";
import NewLogoSophie from "../Assets/LogoSophieSite-removebg-preview (1).png";
import logIn from "../Assets/icons8-utilisateur-64.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import Menu from "../Assets/icons8-menu-arrondi-50.png";
import { useState } from "react";
import Divider from "@mui/material/Divider";

const NavBar = () => {
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:600px)");
  const [displayMenu, setDisplayMenu] = useState(false);

  return (
    <div className="navBar">
      {matches ? (
        <div className="logoNavBar">
          <button className="btnRDV" onClick={() => navigate("/rdvlist")}>
            PRENDRE RDV
          </button>
          <img
            src={NewLogoSophie}
            width="170px"
            alt="logoSophie"
            onClick={() => navigate("/")}
          />
          <img className="logIn" src={logIn} width="30px" alt="logIn" />
        </div>
      ) : (
        <div className="logoNavBar">
          <img
            src={NewLogoSophie}
            width="130px"
            alt="logoSophie"
            style={{ marginLeft: "5px" }}
            onClick={() => navigate("/")}
          />
          <div className="navBarMobile">
            <img
              src={Menu}
              width="20px"
              alt="menu"
              onClick={() => setDisplayMenu(!displayMenu)}
            />
        
          </div>
        </div>
      )}
      {matches && (
        <div className="navBar">
          <div className="link">
          <Link className="linkNavBar" to={"/"}>
              HOME
            </Link>
            <Link className="linkNavBar" to={"/therapie"}>
              THERAPIE
            </Link>
            <Link className="linkNavBar" to={"/sophrologie"}>
              SOPHROLOGIE
            </Link>
            <Link className="linkNavBar" to={"/hypnotherapie"}>
              HYPNOSE THERAPEUTIQUE
            </Link>
            <Link className="linkNavBar" to={"/presta_et_tarifs"}>
              PRESTATIONS & TARIFS
            </Link>
            <Link className="linkNavBar" to={"/contact_et_acces"}>
              CONTACT & ACCES
            </Link>
          </div>
        </div>
      )}
      {displayMenu && (
        <>
        <Link className="linkNavBar" to={"/"} style={{ marginTop: "10px" }}>
              HOME
            </Link>
            <Divider
            variant="middle"
            width="100px"
            style={{
              borderColor: "#CB997E",
              borderRadius: "10px",
              margin: "10px",
            }}
          />
          <Link
            className="linkNavBar"
            to={"/therapie"}

          >
            THERAPIE
          </Link>
          <Divider
            variant="middle"
            width="100px"
            style={{
              borderColor: "#CB997E",
              borderRadius: "10px",
              margin: "10px",
            }}
          />
          <Link className="linkNavBar" to={"/sophrologie"}>
            SOPHROLOGIE
          </Link>
          <Divider
            variant="middle"
            width="100px"
            style={{
              borderColor: "#CB997E",
              borderRadius: "10px",
              margin: "10px",
            }}
          />
          <Link className="linkNavBar" to={"/hypnotherapie"}>
            HYPNOSE THERAPEUTIQUE
          </Link>
          <Divider
            variant="middle"
            width="100px"
            style={{
              borderColor: "#CB997E",
              borderRadius: "10px",
              margin: "10px",
            }}
          />
          <Link className="linkNavBar" to={"/presta_et_tarifs"}>
            PRESTATIONS & TARIFS
          </Link>
          <Divider
            variant="middle"
            width="100px"
            style={{
              borderColor: "#CB997E",
              borderRadius: "10px",
              margin: "10px",
            }}
          />
          <Link
            className="linkNavBar"
            to={"/contact_et_acces"}
            style={{ marginBottom: "10px" }}
          >
            CONTACT & ACCES
          </Link>
        </>
      )}
    </div>
  );
};

export default NavBar;
