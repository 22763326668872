import React, { useState } from "react";
import emailjs from "emailjs-com";

function ContactForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(process.env.REACT_APP_PUBLIC_KEY)
    const templateParams = {
      from_name: name,
      reply_to: email,
      to_name: "YOUR_NAME",
      subject: subject,
      message: message,
    };
    emailjs
      .send(
        "service_hlm32mm",
        "template_sophro",
        templateParams,
        "HY5k_h1fYC-O2CzqW"
      )
      .then(
        (result) => {
          console.log("Success: ", result);
          setName("");
          setEmail("");
          setSubject("");
          setMessage("");
          let span_mail = document.getElementById("span_mail");
          span_mail.style.display = "block";
        },
        (error) => {
          console.log("Error: ", error);
        }
      );
     
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <input
          type="text"
          className="inputContact"
          placeholder="Nom"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div>
        <input
          type="email"
          className="inputContact"
          placeholder="Email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div>
        <input
          type="text"
          className="inputContact"
          placeholder="Objet"
          id="subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
      </div>
      <div>
        <textarea
          id="message"
          placeholder="Message"
          className="inputContactMessage"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
      </div>
      <button className="btnRDV" type="submit">Envoyer</button>
      <span style={{display:"none",color:"#B7B7A4",fontStyle:"italic"}} id="span_mail">Votre message à bien été envoyé, je reviens vers vous dans les plus brefs délais</span>
    </form>
  );
}

export default ContactForm;
