import NavBar from "./NavBar";
import Agenda from "../Assets/pexels-ann-poan-5797898.jpg";
import Divider from "@mui/material/Divider";
import "../Styles/Therapie.css";
import flower from "../Assets/pexels-rodolfo-clix-922609.jpg";
import Footer from "./Multi_Component/Footer";
import Photo1 from "../Assets/pexels-charles-haacker-3618010 (1).jpg"
import Photo2 from "../Assets/pexels-nandhu-kumar-4068961 (1).jpg"


const Sophrologie = () => {
  return (
    <>
      <NavBar />

      <div className="containerTherapieTitle">
        <img className="therapieBackground" src={flower} alt="bureauSophie" />
        <div className="textSophiePresta">
          <div className="divSophiePresta">Sophrologie</div>
          <div className="divider">
            <Divider
              variant="middle"
              width="100px"
              style={{
                borderColor: "#CB997E",
                borderRadius: "10px",
                marginBottom: "30px",
              }}
            />
          </div>
        </div>
      </div>
      <div className="profilecontentframePresta">
        <div
          style={{
            textAlign: "-webkit-center",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <h4>La sophrologie, c’est quoi ? </h4>
          <div className="divider">
            <Divider
              variant="middle"
              width="100px"
              style={{
                borderColor: "#CB997E",
                borderRadius: "10px",
                // marginBottom: "30px",
              }}
            />
          </div>
        </div>
        <div style={{ padding: "0px 20px 0px 20px" }}>
          <img
            src={Photo1}
            alt="photo1"
            style={{
              width: "150px",
              height: "200px",
              float: "left",
              margin: "15px",
              borderRadius: "20px",
            }}
          />
          <p>
            La sophrologie existe depuis les années 70.
            <br />
            C’est une méthode psychocorporelle issue du yoga, de l’hypnose et du
            zen, qui permet de retrouver un bien-être au quotidien en
            développant son potentiel.
            <br />
            La sophrologie est basée sur une technique de détente par des
            exercices de respiration et des exercices de visualisation.
            <br />
            Le corps et l’esprit sont en état de veille, le but étant d’être à
            l’écoute de ses ressentis.
            <br />
          </p>
        </div>
        <div
          style={{
            textAlign: "-webkit-center",
          }}
        >
          <div className="divider">
            <Divider
              variant="middle"
              width="100px"
              style={{
                borderColor: "#CB997E",
                borderRadius: "10px",
                // marginBottom: "30px",
              }}
            />
          </div>
        </div>
        <div
          style={{
            textAlign: "-webkit-center",
            paddingLeft: "15px",
            paddingRight: "15px",
            // marginBottom : "30px"
          }}
        >
          <h4>Pourquoi tenter la sophrologie ? </h4>
          <div className="divider">
            <Divider
              variant="middle"
              width="100px"
              style={{
                borderColor: "#CB997E",
                borderRadius: "10px",
                marginBottom: "30px",
              }}
            />
          </div>
        </div>
        <div style={{ padding: "0px 20px 0px 20px", margin: "auto" }}>
          <img
            src={Photo2}
            alt="photo2"
            style={{
              width: "150px",
              height: "200px",
              float: "right",
              margin: "20px",
              borderRadius: "20px",
            }}
          />
          <p>
            La sophrologie se compose en général de plusieurs séances car nous
            élaborons ensemble un protocole vous permettant d’atteindre vos
            objectifs. <br/> Vous pouvez venir consulter pour :<br/> 
            <ul className="footer-services">
          <li>Réduire votre stress</li>
          <li>Réduire vos angoisses</li>
          <li>Apprendre à gérer vos émotions</li>
          <li>Améliorer votre sommeil</li>
          <li>Faire face à vos phobies</li>
          <li>Vous préparer à un examen</li>
        </ul> <br/>
            Une
            multitude de raisons peuvent vous pousser à venir consulter. <br/>
            Une fois le rendez-vous pris, la moitié du chemin est parcouru !
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Sophrologie;
