

const CardTarif = (props) => {
    return(<>
  <h2 style={{textAlign:"center"}}>{props.type.title}</h2>
  <div style={{display:"flex" , flexDirection:"row",alignItems : "center", flexWrap:"wrap", justifyContent : "center"}}><h5>Première consultation:</h5><p style={{textAlign : "center"}}>{props.type.consult}</p></div>
  <div style={{display:"flex" , flexDirection:"row",alignItems : "center", flexWrap:"wrap",justifyContent : "center"}}><h5>Consultation de suivi:</h5><p style={{textAlign : "center"}}>{props.type.suivi}</p></div>
    </>)
}

export default CardTarif