import NavBar from "./NavBar";
import Agenda from "../Assets/pexels-ann-poan-5797898.jpg";
import Divider from "@mui/material/Divider";
import "../Styles/Contact.css";
import MapComponent from "./Map";
import { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Insta from "../Assets/icons8-instagram-24.png";
import Mail from "../Assets/icons8-message-rempli-24.png";
import Tel from "../Assets/icons8-téléphone-24.png";
import Cabinet from "../Assets/cabinet.jpg";
import Cabinet2 from "../Assets/cabinet2.jpg";
import ContactForm from "./Multi_Component/ContactForm";
import Footer from "./Multi_Component/Footer";

const Contact = () => {
  const navigate = useNavigate();
  const [sendMail,setSendMail] = useState(false)
  const greenIcon = new L.Icon({
    iconUrl:
      "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png",
    shadowUrl:
      "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

  return (
    <>
      <NavBar />

      <div className="containerImgSophieAgenda">
        <img className="sophieAgenda" src={Agenda} alt="agendaPicture" />
        <div className="textSophieAgenda">
          <div className="divSophieAgenda">Contact & Accès</div>
          <div className="divider">
            <Divider
              variant="middle"
              width="100px"
              style={{
                borderColor: "#CB997E",
                borderRadius: "10px",
                marginBottom: "30px",
              }}
            />
          </div>
          <div className="divSophieAgendaSecond">
            Situé à deux pas du centre ville et de la maison de santé de{" "}
            <span>Paray-Vieille-Poste,</span> au sein d'un cabinet médical,
            venez me retrouver dans un cadre bienveillant et chaleureux pour un
            moment qui vous est dédié.
          </div>
        </div>
        {/* <div className="containerBtnDown">
        <button className="btnDown"><img className="down" src={ScrollDown} width="30px" alt="down"/></button>
        </div> */}
      </div>
      <div className="profilecontentframe">
        <div className="contact">
          <h4>Adresse</h4>
          <p>110 avenue d'Alsace Lorraine</p>
          <p>91550 Parray-Vieille-Poste</p>
          <Divider
            variant="middle"
            width="100px"
            style={{
              borderColor: "#CB997E",
              borderRadius: "10px",
              marginBottom: "20px",
            }}
          />
          <h4>Email</h4>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <img
              src={Mail}
              alt="mail"
              style={{ width: "20px", height: "20px" }}
            />
            <p>sophie.hypnosophro@gmail.com</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
            }}
            onClick={() => window.open('https://instagram.com/sophie_m_therapie?igshid=ZDdkNTZiNTM=', '_blank')}>
            <img
              src={Insta}
              alt="insta"
              style={{ width: "20px", height: "20px" }}
            />
            <p>@sophie_m_therapie</p>
          </div>
          <Divider
            variant="middle"
            width="100px"
            style={{
              borderColor: "#CB997E",
              borderRadius: "10px",
              marginBottom: "20px",
            }}
          />
          <h4>Téléphone</h4>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <img
              src={Tel}
              alt="tel"
              style={{ width: "20px", height: "20px" }}
            />
            <p>06 63 46 91 78</p>
          </div>
          <Divider
            variant="middle"
            width="100px"
            style={{
              borderColor: "#CB997E",
              borderRadius: "10px",
              marginBottom: "20px",
            }}
          />
          <button className="btnRDV" onClick={() => navigate("/rdvlist")}>
            PRENDRE RDV
          </button>
          <button className="btnRDV" onClick={() => setSendMail(!sendMail)}>
            ME CONTACTER
          </button>
        </div>
        { sendMail &&
          <div style={{width : "100%",textAlign : "center"}}>
      <h1 style={{color :"#c5a28a"}}>M'écrire</h1>
      <ContactForm />
    </div>
        }
        <div className="map">
          <MapContainer center={[48.7148409, 2.3607702]} zoom={16} maxZoom={17}>
            <Marker position={[48.7148409, 2.3607702]} icon={greenIcon}>
              <Popup>Votre localisation</Popup>
            </Marker>

            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
          </MapContainer>
        </div>
        <div
          className="cabinet"
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            backgroundColor: "#fcf3ed",
            width: "100%",
          }}
        >
          <h4>Votre cabinet</h4>
          <div>
            <img
              src={Cabinet}
              alt="cabinet"
              style={{ width: "250px", height: "150px", borderRadius: "200px" }}
            />
          </div>
          <div>
            <img
              src={Cabinet2}
              alt="cabinet2"
              style={{ width: "250px", height: "150px", borderRadius: "200px" }}
            />
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default Contact;
