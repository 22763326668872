import NavBar from "./NavBar";
import Agenda from "../Assets/pexels-ann-poan-5797898.jpg";
import Divider from "@mui/material/Divider";
import "../Styles/Therapie.css";
import flower from "../Assets/pexels-rodolfo-clix-922609.jpg";
import Photo1 from "../Assets/hypnotherapiePhoto1.png";
import Photo2 from "../Assets/hypnotherapiePhoto2.png";
import Footer from "./Multi_Component/Footer"

const Hypnotherapie = () => {
  return (
    <>
      <NavBar />

      <div className="containerTherapieTitle">
        <img className="therapieBackground" src={flower} alt="bureauSophie" />
        <div className="textSophiePresta">
          <div className="divSophiePresta">Hypnose Thérapeutique</div>
          <div className="divider">
            <Divider
              variant="middle"
              width="100px"
              style={{
                borderColor: "#CB997E",
                borderRadius: "10px",
                marginBottom: "30px",
              }}
            />
          </div>
        </div>
      </div>
      <div className="profilecontentframePresta">
        <div
          style={{
            textAlign: "-webkit-center",
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <h4>L’hypnose thérapeutique, c’est quoi ?</h4>
          <div className="divider">
            <Divider
              variant="middle"
              width="100px"
              style={{
                borderColor: "#CB997E",
                borderRadius: "10px",
                // marginBottom: "30px",
              }}
            />
          </div>
        </div>
        <div style={{ padding: "0px 20px 0px 20px" }}>
          <img
            src={Photo1}
            alt="photo1"
            style={{
              width: "150px",
              height: "200px",
              float: "left",
              margin: "20px",
              borderRadius: "20px",
            }}
          />
          <p>
            L’hypnose fait beaucoup fantasmer, parler et débattre. Pratiquée
            depuis le 18ème siècle, que ce soit en médecine ou en psychiatrie,
            elle est utilisée à des fins thérapeutiques depuis la fin des années
            1980.{" "}
            <p>
              {" "}
              L’état d’hypnose est en un phénomène naturel que tout le monde vit
              dans son quotidien. Il s’agit d’un état de conscience modifié,
              proche du sommeil ou de l’état méditatif qui permet d’accéder aux
              clefs de notre inconscient. <br /> L'hypnose est reconnue comme
              médecine alternative et complémentaire, ne remplaçant pas un
              traitement médical mais pouvant accompagner le consultant dans sa
              guérison. La grande force de l’hypnose est d’aller chercher chez
              chaque personnes les ressources inconscientes dont il dispose afin
              de soigner ses maux psychiques et/ou physiques.
            </p>
          </p>
        </div>
        <div
          style={{
            textAlign: "-webkit-center",
          }}
        >
        <div className="divider">
          <Divider
            variant="middle"
            width="100px"
            style={{
              borderColor: "#CB997E",
              borderRadius: "10px",
              // marginBottom: "30px",
            }}
          />
        </div>
        </div>
        <div
          style={{
            textAlign: "-webkit-center",
            paddingLeft: "15px",
            paddingRight: "15px",
            // marginBottom : "30px"
          }}
        >
          <h4>L’inconscient et le conscient, quelle différence ?</h4>
          <div className="divider">
            <Divider
              variant="middle"
              width="100px"
              style={{
                borderColor: "#CB997E",
                borderRadius: "10px",
                marginBottom: "30px",
              }}
            />
          </div>
        </div>
        <div style={{ padding: "0px 20px 0px 20px",margin : "auto"  }}>
          <img
            src={Photo2}
            alt="photo1"
            style={{
              width: "150px",
              height: "200px",
              float: "right",
              margin: "20px",
              borderRadius: "20px",
            }}
          />
          <p>
            En tant que thérapeute, mon rôle est de vous guider lors d’une
            séance afin de vous amener plus facilement et plus rapidement dans
            un état d’hypnose. <br /> Une fois cet état atteint, je suis en mesure de
            m’adresser à votre inconscient afin d’aller déverrouiller les
            émotions et regler une problématique personnelle.<p> L’état d’hypnose
            est un état de bien être, vous n’avez rien à dire, rien à faire et
            simplement vous laisser guider par ma voix.
            </p>
          </p>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default Hypnotherapie;
