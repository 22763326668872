import React, { Component } from 'react';

class Calendly extends Component {
  componentDidMount() {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
    head.appendChild(script);
  }

  componentWillUnmount() {
    const head = document.querySelector('head');
    const script = document.querySelector('script[src="https://assets.calendly.com/assets/external/widget.js"]');
    head.removeChild(script);
  }

  render() {
    return (
        <div className="calendly-inline-widget" data-url="https://calendly.com/sophie-therapie?background_color=fcf3ed&primary_color=ddbea9" style={{width:"100%",height:"100%"}} 
          ></div>
    );
  }
}

export default Calendly;
