import NavBar from "./NavBar";
import Agenda from "../Assets/pexels-ann-poan-5797898.jpg";
import Divider from "@mui/material/Divider";
import "../Styles/Presta.css";
import "../Styles/Therapie.css";
import MapComponent from "./Map";
import { useState, useEffect, useRef } from "react";
import TherapieEnfant from "../Assets/Capture_d_écran_2023-02-19_à_21.17.04-removebg-preview.png";
import SeanceSophroEnfant from "../Assets/Capture_d_écran_2023-02-19_à_21.26.48-removebg-preview.png";
import HypnoThérapieEnfant from "../Assets/Capture_d_écran_2023-02-19_à_21.36.31-removebg-preview.png";
import HypnoThérapieAdulte from "../Assets/Capture_d_écran_2023-02-19_à_23.23.40-removebg-preview.png";
import SeanceSophroAdulte from "../Assets/Capture_d_écran_2023-02-19_à_23.45.29-removebg-preview.png";
import TherapieAdulte from "../Assets/Capture_d_écran_2023-02-19_à_23.49.35-removebg-preview.png";
import CardTarif from "./Multi_Component/CardTarifs";
import { useNavigate } from "react-router-dom";
import PrestaImg from "../Assets/Presta.jpg";
import Footer from "./Multi_Component/Footer";

const Presta = () => {
    const navigate = useNavigate()
  const [displayEnfant, setDisplayEnfant] = useState(false);
  const [displayAdulte, setDisplayAdulte] = useState(false);
  const [displayType,setDisplayType] = useState(true);

  const type = [
    { title: "Thérapie Enfant", consult: "50€", suivi: "40€" },
    { title: "Séance de Sophrologie Enfant", consult: "50€", suivi: "40€" },
    { title: "Hypnose Thérapeutique Enfant", consult: "60€", suivi: "50€" },
    { title: "Thérapie Adulte", consult: "60€", suivi: "50€" },
    { title: "Séance de Sophrologie Adulte", consult: "60€", suivi: "50€" },
    { title: "Hypnose Thérapeutique Adulte", consult: "70€", suivi: "60€" },
  ];
  const [index, setIndex] = useState(0);

  const on = (numIndex) => {
    setIndex(numIndex);
    document.getElementById("overlay").style.display = "block";
  };

  function off() {
    document.getElementById("overlay").style.display = "none";
  }

  return (
    <>
      <NavBar />
      <div className="containerTherapieTitle">
      <img src={PrestaImg} className="therapieBackground" alt="presta" style={{width : "100%"}}/>
        {/* <div className="sophiePresta"></div> */}
        <div className="textSophiePresta">
          <div className="divSophiePresta">Prestations & Tarifs</div>
          <div className="divider">
            <Divider
              variant="middle"
              width="100px"
              style={{
                borderColor: "#CB997E",
                borderRadius: "10px",
                marginBottom: "20px",
              }}
            />
          </div>
          </div>
        </div>
    

{displayType &&
<div style={{backgroundColor: "#fcf3ed"}}>
          <h4 style={{margin : "0px",color : "#c5a28a",textAlign:"center",paddingTop : "50px",paddingBottom : "50px"}}>Une séance pour qui ? </h4>
         <div className="divSophiePrestaSecond">
            <button
              className="btnSophiePresta"
              onClick={() =>
                setDisplayEnfant(!displayEnfant) & setDisplayAdulte(false) & setDisplayType(false)
              }
            >
              Séance Enfant
            </button>
            <button
              className="btnSophiePresta"
              onClick={() =>
                setDisplayAdulte(!displayAdulte) & setDisplayEnfant(false) & setDisplayType(false)
              }
            >
              Séance Adulte
            </button>
            </div>
            </div>   
} 
        {displayEnfant && (
            <div style={{backgroundColor: "#fcf3ed"}}>
            <h4 style={{margin : "0px",color : "#c5a28a",textAlign:"center",paddingTop : "50px",paddingBottom : "50px"}}>Quelle séance souhaitez vous ? </h4>
            <div className="profilecontentframePresta">
          <div className="containerTypeSeance" style={{paddingBottom : "30px"}}>
            <button className="btnTypeSeance" onClick={() => on(0)}>
              <img
                src={TherapieEnfant}
                className="imgBtnTypeSeance"
                alt="therapieEnfant"
              />
            </button>
            <button className="btnTypeSeance" onClick={() => on(1)}>
              <img
                src={SeanceSophroEnfant}
                className="imgBtnTypeSeance"
                alt="seanceSophroEnfant"
              />
            </button>
            <button className="btnTypeSeance" onClick={() => on(2)}>
              <img
                src={HypnoThérapieEnfant}
                className="imgBtnTypeSeance"
                alt="hypnoThérapieEnfant"
              />
            </button>
          </div>
          <button
              className="btnSophiePresta"
              onClick={() =>
                setDisplayAdulte(false) & setDisplayEnfant(false) & setDisplayType(true)
              }
            >
              Retour au choix
            </button>
          </div>
          </div>
        )}

        {displayAdulte && (
            <div className="profilecontentframePresta">
          <div className="containerTypeSeance" style={{paddingBottom : "30px"}}>
            <button className="btnTypeSeanceAdulte" onClick={() => on(3)}>
              <img
                src={TherapieAdulte}
                className="imgBtnTypeSeanceAdulte"
                alt="therapieAdulte"
              />
            </button>
            <button className="btnTypeSeanceAdulte" onClick={() => on(4)}>
              <img
                src={SeanceSophroAdulte}
                className="imgBtnTypeSeanceAdulte"
                alt="seanceSophroAdulte"
              />
            </button>
            <button className="btnTypeSeanceAdulte" onClick={() => on(5)}>
              <img
                src={HypnoThérapieAdulte}
                className="imgBtnTypeSeanceAdulte"
                alt="hypnoThérapieAdulte"
              />
            </button>
          </div>
          <button
              className="btnSophiePresta"
              onClick={() =>
                setDisplayAdulte(false) & setDisplayEnfant(false) & setDisplayType(true)
              }
            >
              Retour au choix
            </button>
          </div>
        )}
          <div id="overlay">
        <div id="text">
          <div className="profilecontentOverLay">
            <CardTarif type={type[index]} />
            <div style={{ display: "flex", flexDirection: "row" }}>
              <button className="btnRDV" onClick={() => navigate("/rdvlist")}>Prendre RDV</button>
              <button className="btnRDV" onClick={() => off()}>
                Retour
              </button>
            </div>
          </div>
        </div>
      </div>
     <Footer/>
    </>
  );
};

export default Presta;
